import React from 'react'

function BookOneRelease() {
  return (
	<section className="upcoming-games-area pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="book-single-title mb-55">
                <h4>Book one released <span>The Beginning</span></h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="upcoming-game-item mb-40">
                <div className="upcoming-book-head">
                  <div className="uc-books-head-title">
                    <span>November 14, 2023</span>
                    <h4><a href="https://www.amazon.co.uk/dp/1738510514" target="_blank" rel="noopener noreferrer">The Beginning (Paperback)</a></h4>
                  </div>
                  <div className="uc-book-price">
                    <h5>From £7.99</h5>
                  </div>
                </div>
                <p>First book in the Oliver and the Time Machine series, paperback version</p>
                <div className="upcoming-game-thumb">
                  <img src="assets/img/images/book1-full-cover.jpg" alt="" />
                  <div className="upcoming-game-cart">
                    <a href="https://www.amazon.com/dp/1738510514" target="_blank" rel="noopener noreferrer" className="btn transparent-btn"><i className="fas fa-shopping-basket" />BUY Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="upcoming-game-item mb-40">
                <div className="upcoming-book-head">
                  <div className="uc-books-head-title">
                    <span>Release date: December 15, 2023</span>
                    <h4><a href="https://www.amazon.com/gp/product/B0CSF4XZYT" target="_blank" rel="noopener noreferrer" >The Beginning (Kindle, E-book)</a></h4>
                  </div>
                  <div className="uc-book-price">
                    <h5>From £1.99</h5>
                  </div>
                </div>
                <p>First book in the Oliver and the Time Machine series, Kindle, E-book version</p>
                <div className="upcoming-game-thumb">
                  <img src="assets/img/images/book1-full-cover.jpg" alt="" />
                  <div className="upcoming-game-cart">
                    <a href="https://www.amazon.com/gp/product/B0CSF4XZYT" target="_blank" rel="noopener noreferrer" className="btn transparent-btn"><i className="fas fa-shopping-basket" />BUY Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default BookOneRelease