import React from 'react';
import { Helmet } from 'react-helmet';
import CookieConsent from "react-cookie-consent";
import Breadcrumb from './Breadcrumb';
import HeaderTwo from '../../components/header/HeaderTwo';
import Footer from '../../components/footer/Footer';
import ArtworkArea from './ArtworkArea';

function Overview() {
  return (
	<>
	<Helmet>
		<link rel="canonical" href="https://oliverandthetimemachine.com/artwork" />
		<title>Oliver and the Time Machine - OATTM Artwork</title>
		<meta name="description" content="Artwork from the Oliver and the Time Machine series. Free downloads for personal use." />
	</Helmet>
		<HeaderTwo/>
		<main>
			<Breadcrumb/>
			<ArtworkArea/>
			<CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>

		</main>
		<Footer/>
	</>
  )
}

export default Overview