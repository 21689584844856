import React, { useEffect } from 'react'
import {Link} from 'react-router-dom'
import $ from 'jquery'



export default function HeaderTwo() {

  
  useEffect(()=>{
    //SubMenu Dropdown Toggle
      if ($('.menu-area li.menu-item-has-children ul').length) {
        $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');

      }

      //Mobile Nav Hide Show
      if ($('.mobile-menu').length) {

        var mobileMenuContent = $('.menu-area .push-menu').html();
        $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

        //Dropdown Button
        $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
          $(this).toggleClass('open');
          $(this).prev('ul').slideToggle(500);
        });


        $('.menu-backdrop, .mobile-menu .close-btn').click (()=>{
          $('body').removeClass('mobile-menu-visible');
        })


        //Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function () {
          $('body').addClass('mobile-menu-visible');
        });
      }
      

  },[]);

  useEffect(()=>{
    
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $('.scroll-to-target').removeClass('open');
    
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $('.scroll-to-target').addClass('open');
      }
    });
    
  },[])


  
  return (
	 <header> 
          <div className="header-top-area s-header-top-area d-none d-lg-block">
            <div className="container-fluid s-container-full-padding">
              <div className="row align-items-center">
                <div className="col-lg-6 d-none d-lg-block">
                 {/* <div className="header-top-offer">
                    <p>Exclusive Black Friday ! Offer</p>
                    <span className="coming-time" data-countdown="2022/3/15" />
                </div>*/}
                </div>
                <div className="col-lg-6">
                  <div className="header-top-right">
                    <div className="header-social">
                      <ul>
                        <li><Link to="https://www.facebook.com/profile.php?id=61553549675632" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></Link></li>
                        <li><Link to="https://www.tiktok.com/@oattmbooks" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok" /></Link></li>
                        <li><Link to="https://www.instagram.com/@oattm1" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></Link></li>
                      </ul>
                    </div>
                    <div className="header-top-action">
                      <ul>
                        <li>
                         {/*} <div className="header-top-mail">
                            <p><span>|</span><i className="far fa-envelope" /><Link to="/mailto:hello@oliverandthetimemachine.com">hello@oliverandthetimemachine.com</Link></p>
                          </div>*/}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="sticky-header" className="transparent-header menu-area">
            <div className="container-fluid s-container-full-padding">
              <div className="row">
                <div className="col-12">
                <div className="mobile-nav-toggler two"><i className="fas fa-bars" /></div>
                  <div className="main-menu  menu-style-two">
                    <nav>
                      <div className="header-logo">
                        <Link to="/"><img src="assets/img/logo/logo.png" alt="OATTM Logo" /></Link>
                      </div>
                      <div className="navbar-wrap push-menu d-none d-lg-flex">
                        <ul className="navigation">
                              <li ><Link to="/">Home</Link></li>

                              <li><Link to="/book-one-the-discovery">Book One</Link></li>
                              <li><Link to="/book-two-the-awakening">Book Two</Link></li>
                              <li><Link to="/book-three-the-tbc">Book Three</Link></li>
                              <li><Link to="/artwork">AI Artwork</Link></li>
                          <li><Link to="/videos">AI Videos</Link></li>
                          <li><Link to="/music">Music</Link></li>
                          <li><Link to="/roblox">Roblox Skins</Link></li>
                          <li><Link to="/shop">Merchandise</Link></li>
                                                       
                          </ul>
                      </div>
                    </nav>
                  </div>
                
                 <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn"><i className="fas fa-times" /></div>
                    <div className="nav-logo"><Link to="/"><img src="assets/img/logo/logo.png" alt="" title='true' /></Link>
                    </div>
                    <div className="menu-outer">
                  
                    </div>
                    <div className="social-links">
                      <ul className="clearfix">
                        <li><a href="/#"><span className="fab fa-twitter" /></a></li>
                        <li><a href="/#"><span className="fab fa-facebook-square" /></a></li>
                        <li><a href="/#"><span className="fab fa-pinterest-p" /></a></li>
                        <li><a href="/#"><span className="fab fa-instagram" /></a></li>
                        <li><a href="/#"><span className="fab fa-youtube" /></a></li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" />
              </div>
              </div>
            </div>
          </div>
        </header>
  )
}
