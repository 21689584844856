import React from 'react';
import { Helmet } from 'react-helmet';
import HeaderTwo from '../../components/header/HeaderTwo';
import BreadCrumb from './BreadCrumb';
import Footer from '../../components/footer/Footer';

import OATTMMusic from './OATTMMusic';


function AiMusic() {
  return (
	<>
	<Helmet>
		<link rel="canonical" href="https://oliverandthetimemachine.com/music" />
		<title>Oliver and the Time Machine - OATTM Music</title>
		<meta name="description" content="Music from the Oliver and the Time Machine series. ChapterVerse One is a journey of music following each chapter of book one, The Beginning" />
	</Helmet>
	<HeaderTwo/>
	<BreadCrumb/>
	<main>
	<OATTMMusic/>
	</main>
	<Footer/>
	</>
  )
}

export default AiMusic