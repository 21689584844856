import React,{ useEffect } from 'react'
import $ from "jquery";
import 'magnific-popup';

const OattmVideos = () => {

  useEffect(()=>{

		$('.popup-video').magnificPopup({
			type: 'iframe'
		});
	},[])
  
  return (
    <section className="trending-video-area">
        <div className="container">
          <div className="row align-items-center mb-30">
            <div className="col-sm-6">
              <div className="hf-section-title">
                <h4 className="title">OATTM Short Videos</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="trending-video-bookplay-item mb-50">
                <div className="videoplay-thumb">
                  <a href="https://www.youtube.com/watch?v=f7W1dUfsW6w" className="popup-video">
                    <img src="assets/img/icon/v_play.png" alt="" className="play" />
                    <img src="assets/img/images/youtube_working_vid.jpg" alt="Oliver and the Time Machine AI workflow" />
                  </a>
                  <div className="trend-videoplay-overlay">
                    <ul>
                      <li className="duration">01 :45</li>
                      <li className="quality">hd</li>
                    </ul>
                  </div>
                </div>
                <div className="d-block d-sm-flex align-items-start">
                  <div className="videoplay-content">
                    <h5 className="title"><a href="https://www.youtube.com/watch?v=f7W1dUfsW6w" target="_blank" rel="noopener noreferrer">Book one image workflow using Midjourney</a></h5>
                    <div className="videoplay-meta">
                      <ul>
                        <li>November 14, 2023</li>
                      </ul>
                    </div>
                  </div>
                  <div className="videoplay-status">
                    <span>New</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="trending-video-bookplay-item mb-50">
                <div className="videoplay-thumb">
                  <a href="https://www.youtube.com/watch?v=IGfm71ggjwE" className="popup-video">
                    <img src="assets/img/icon/v_play.png" alt="" className="play" />
                    <img src="assets/img/images/youtube_book1_intro.jpg" alt="Oliver and the Time Machine book introduction" />
                  </a>
                  <div className="trend-videoplay-overlay">
                    <ul>
                      <li className="duration">00 :24</li>
                      <li className="quality">hd</li>
                    </ul>
                  </div>
                </div>
                <div className="d-block d-sm-flex align-items-start">
                  <div className="videoplay-content">
                    <h5 className="title"><a href="https://www.youtube.com/watch?v=IGfm71ggjwE" target="_blank" rel="noopener noreferrer">OFFICIAL BOOK TRAILER - Oliver and the Time Machine Book 1</a></h5>
                    <div className="videoplay-meta">
                      <ul>
                        <li>August 31, 2024</li>
                      </ul>
                    </div>
                  </div>
                  <div className="videoplay-status">
                    <span>New</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="trending-video-bookplay-item mb-50">
                <div className="videoplay-thumb">
                  <a href="https://www.youtube.com/watch?v=Dz9jP_nEgQE" className="popup-video">
                    <img src="assets/img/icon/v_play.png" alt="" className="play" />
                    <img src="assets/img/images/youtube_the_discovery.jpg" alt="OATTM - Book 1 - ChapterVerse One - The Discovery" />
                  </a>
                  <div className="trend-videoplay-overlay">
                    <ul>
                      <li className="duration">02:48</li>
                      <li className="quality">hd</li>
                    </ul>
                  </div>
                </div>
                <div className="d-block d-sm-flex align-items-start">
                  <div className="videoplay-content">
                    <h5 className="title"><a href="https://www.youtube.com/watch?v=Dz9jP_nEgQE" target="_blank" rel="noopener noreferrer">OATTM - Book 1 - ChapterVerse One - The Discovery</a></h5>
                    <div className="videoplay-meta">
                      <ul>
                        <li>September 21, 2024</li>
                      </ul>
                    </div>
                  </div>
                  <div className="videoplay-status">
                    <span>New</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="trending-video-bookplay-item mb-50">
                <div className="videoplay-thumb">
                  <a href="https://www.youtube.com/watch?v=C5w_9uFTX_s" className="popup-video">
                    <img src="assets/img/icon/v_play.png" alt="" className="play" />
                    <img src="assets/img/images/youtube_saving_martians.jpg" alt="OATTM - Book 1 - ChapterVerse Two - Saving the Martians" />
                  </a>
                  <div className="trend-videoplay-overlay">
                    <ul>
                      <li className="duration">02:09</li>
                      <li className="quality">hd</li>
                    </ul>
                  </div>
                </div>
                <div className="d-block d-sm-flex align-items-start">
                  <div className="videoplay-content">
                    <h5 className="title"><a href="https://www.youtube.com/watch?v=C5w_9uFTX_s" target="_blank" rel="noopener noreferrer">OATTM - Book 1 - ChapterVerse Two - Saving the Martians</a></h5>
                    <div className="videoplay-meta">
                      <ul>
                        <li>September 21, 2024</li>
                      </ul>
                    </div>
                  </div>
                  <div className="videoplay-status">
                    <span>New</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="trending-video-bookplay-item mb-50">
                <div className="videoplay-thumb">
                  <a href="https://www.youtube.com/watch?v=yJJfkP8c3To" className="popup-video">
                    <img src="assets/img/icon/v_play.png" alt="" className="play" />
                    <img src="assets/img/images/youtube_drake_weather_machine_intro.jpg" alt="OATTM - Book 1 - ChapterVerse Three - Quantanite" />
                  </a>
                  <div className="trend-videoplay-overlay">
                    <ul>
                      <li className="duration">02:54</li>
                      <li className="quality">hd</li>
                    </ul>
                  </div>
                </div>
                <div className="d-block d-sm-flex align-items-start">
                  <div className="videoplay-content">
                    <h5 className="title"><a href="https://www.youtube.com/watch?v=yJJfkP8c3To" target="_blank" rel="noopener noreferrer">OATTM - Book 1 - ChapterVerse Three - Quantanite</a></h5>
                    <div className="videoplay-meta">
                      <ul>
                        <li>September 24, 2024</li>
                      </ul>
                    </div>
                  </div>
                  <div className="videoplay-status">
                    <span>New</span>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
  )
}

export default OattmVideos