import React from 'react'

function BookTwoArea() {
  return (
    <section className="book-single-area pt-120 pb-120">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <article className="book-single-content">
                  <header className="upcoming-book-head">
                    <div className="uc-books-head-title">
                      <time dateTime="2025-05-01">May 1st, 2025</time>
                      <h1>Oliver and the Time Machine <span>The Awakening</span></h1>
                    </div>
                    <div className="uc-book-price">
                      <a href="https://www.amazon.com/gp/product/B0CSF4XZYT" 
                         target="_blank" 
                         rel="noopener noreferrer" 
                         className="btn transparent-btn"
                         aria-label="Buy book from £1.99">
                        <h5>Release due May 2025</h5>
                      </a>
                    </div>
                  </header>
  
                  <section className="book-synopsis">
                  <div className="book-single-title mt-60 mb-30">
                    <h4>Synopsis</h4>
                  </div>
                    <p><strong>Book Two: The Awakening</strong></p>
                    <p>The story continues... Will Oliver solve the puzzle? Will the materplan be revealed?</p>
                  </section>
  
                  <div className="book-info-single-img">
                    <div className="row">
                      <div className="col-sm-12">
                        <img src="assets/img/newcover-book2.jpg" 
                             alt="Oliver and the Time Machine book 2 cover" style={{maxWidth: '40%'}}/>
                      </div>
                    </div>
                  </div>

                  <div className="book-single-shape"><img src="assets/img/images/game_section_shape.png" alt="" /></div>
                </article>
              </div>
            </div>
          </div>
        </section>
    )
  }
  

export default BookTwoArea