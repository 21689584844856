import React from 'react'

function BookOneArea() {
  return (
	<section className="book-single-area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <article className="book-single-content">
                <header className="upcoming-book-head">
                  <div className="uc-books-head-title">
                    <time dateTime="2023-11-14">November 14, 2023</time>
                    <h1>Oliver and the Time Machine <span>The Beginning</span></h1>
                  </div>
                  <div className="uc-book-price">
                    <a href="https://www.amazon.com/gp/product/B0CSF4XZYT" 
                       target="_blank" 
                       rel="noopener noreferrer" 
                       className="btn transparent-btn"
                       aria-label="Buy book from £1.99">
                      <h5>Buy from £1.99 paperback or ebook</h5>
                    </a>
                  </div>
                </header>

                <section className="book-synopsis">
                <div className="book-single-title mt-60 mb-30">
                  <h4>Synopsis</h4>
                </div>
                  <p><strong>Book One: The Beginning</strong></p>
                  <p>When ten-year-old Oliver Stone discovers a mysterious red car hidden in his grandad’s shed, he has no idea it’s actually a time machine—complete with a witty AI named Rosie. </p><p>One moment, he’s dodging bullies and schoolwork. The next, he’s outrunning dinosaurs, sneaking through futuristic cities, and uncovering secrets that were never meant to be found.</p>

<p>But Oliver’s adventures aren’t just fun and games. A powerful tech tycoon from the future, Drake Cybernox, has his sights set on controlling time itself—and he’ll stop at nothing to do it.</p> <p>With Rosie’s help and a growing sense of courage, Oliver finds himself in a race against time to stop Drake’s plan before history is rewritten for good.</p>

<p>From the prehistoric wilds to high-tech worlds and everything in between, Oliver and the Time Machine is an exciting, funny, and heartfelt adventure about bravery, friendship, and the small choices that shape the future.</p>
                </section>

                <div className="book-info-single-img">
                  <div className="row">
                    <div className="col-sm-6">
                      <img src="assets/img/images/book_single_img01.jpg" 
                           alt="Oliver and the Time Machine book cover" />
                    </div>
                    <div className="col-sm-6">
                      <img src="assets/img/images/book_single_img02.jpg" 
                           alt="Scene from Oliver and the Time Machine" />
                    </div>
                  </div>
                </div>
                <p>Images in the book are generated using AI and remastered in Photoshop (see below)</p>
                <div className="book-single-title mt-60 mb-30">
                  <h4>Information</h4>
                </div>
                <div className="book-single-info mb-65">
                  <ul>
                    <li><span>Characters :</span> Oliver Stone, Drake Cybernox, Rosie the AI, Mr Knowsley, Jenny Driffield</li>
                    <li><span>Theme :</span> Space and Time Travel adventures, Middle Grade, Science Fiction, Time Travel</li>
                    <li><span>Format :</span> Paperback, Kindle, ePUB</li>
                    <li><span>Reading age :</span> Suited to Middle Grades 8 to 14 years, independent readers</li>
                  </ul>
                </div>

                <div className="book-single-title mt-60 mb-30">
                  <h4>Characters</h4>
                </div>
                <div className="book-single-info mb-65">
                  <ul>
                    <li className="character-profile">
                      <div className="character-image">
                        <img src="assets/img/characters/oliver_stone.jpg" alt="Oliver Stone character" />
                      </div>
                      <div className="character-description">
                        <span>Oliver Stone:</span>
                        Curious, clever, and unexpectedly brave—Oliver is a ten-year-old boy whose life takes a wild turn when he discovers a time-travelling car. Guided by his quick thinking and strong sense of right and wrong, Oliver journeys through the past and future, learning that even small choices can make a big difference. Whether he's outsmarting androids or rescuing lost inventors, Oliver is a true adventurer—just not always intentionally.
                      </div>
                    </li>
                    
                    <li className="character-profile">
                      <div className="character-image">
                        <img src="assets/img/characters/drake_cybernox.jpg" alt="Drake Cybernox character" />
                      </div>
                      <div className="character-description">
                        <span>Drake Cybernox:</span>
                        A brilliant mind turned dangerous. Drake is a powerful tech magnate from the future who will stop at nothing to rewrite history in his favour. Cold, calculating, and obsessed with control, Drake sees emotions as flaws—and believes the world is better off without them. But behind the perfect suits and towering skyscrapers lies a mystery. What made Drake the way he is?
                      </div>
                    </li>
                    
                    <li className="character-profile">
                      <div className="character-image">
                        <img src="assets/img/characters/rosie_the_ai.jpg" alt="Rosie the AI character" />
                      </div>
                      <div className="character-description">
                        <span>Rosie the AI:</span>
                        Rosie is not your typical voice in a machine. Programmed to assist with time travel and protect Oliver, she's logical, loyal, and occasionally sassy. With glowing lights, a calm voice, and a mind that never sleeps, Rosie offers guidance, facts, and the occasional witty remark. As the story unfolds, her programming evolves in ways no one expected—least of all Rosie.
                      </div>
                    </li>
                    
                    <li className="character-profile">
                      <div className="character-image">
                        <img src="assets/img/characters/jenny_driffield.jpg" alt="Jenny Driffield character" />
                      </div>
                      <div className="character-description">
                        <span>Jenny Driffield:</span>
                        Smart, brave, and always up for a challenge, Jenny is more than just Oliver's classmate. With a knack for leading community projects and a quick sense of humour, she's someone Oliver admires—even if he's not always sure how to talk to her. As the stakes rise, Jenny may play a bigger role in Oliver's adventures than either of them ever imagined.
                      </div>
                    </li>

                    <li className="character-profile">
                      <div className="character-image">
                        <img src="assets/img/characters/mr_knowsley.jpg" alt="Mr Knowsley character" />
                      </div>
                      <div className="character-description">
                        <span>Mr Knowsley:</span>
                        Eccentric, kind, and possibly a retired secret agent (depending on who you ask), Mr Knowsley is the local inventor with a shed full of strange devices and half-finished blueprints. He may look like your average neighbour, but his past holds secrets—some of which may be more connected to Oliver's journey than anyone realises.
                      </div>
                    </li>
                  </ul>
                </div>

                
                <div className="book-single-title mb-30">
                  <h4>Image Generation <span>Workflow</span></h4>
                </div>

                <p>Oliver and the Time Machine features AI (Artificial Intelligence) generated images to aid and enhance the storylines. Thousands of images were generated during the writing of book one, with approximately 50 images being used. You can view a video of the image workflow <a href="https://www.youtube.com/watch?v=f7W1dUfsW6w" target="_blank" rel="noopener noreferrer"> here</a></p>
                <p> <strong>Why use AI?</strong></p>
                <p>As the author, there were several reasons why I chose AI to generate the images. The time taken to create an image (very fast), cost and the ability to create what I had in my mind were just some of the main factors. </p>
                <p>During the book writing journey, I found that it also enhanced <strong>creativity and innovation:</strong> With AI, it's possible to generate unique, imaginative visual content that human designers might not think of.
                   This capability can push the boundaries of traditional design and storytelling, leading to fresh, innovative content.</p>
                   <p>Below are some of the images that were generated during the book writing process. These are some of the earliest images and vary from the actual images used in book one. Check AI Artwork in the menu for FREE downloadable images </p>
                <div className="book-single-gallery">
                  <div className="row">
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery01.jpg" alt="Early Oliver" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery02.jpg" alt="Early wristband" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery03.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery04.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery05.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery06.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery07.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery08.jpg" alt="" />
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <img src="assets/img/images/oattm_ai_single_gallery09.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="book-single-shape"><img src="assets/img/images/game_section_shape.png" alt="" /></div>
              </article>
            </div>
          </div>
        </div>
      </section>
  )
}

export default BookOneArea