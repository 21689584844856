import React from 'react';
import HeaderTwo from '../../components/header/HeaderTwo';
import BreadCrumb from './BreadCrumb';
import Footer from '../../components/footer/Footer';
import { Helmet } from 'react-helmet';
import OATTMVideos from './OATTMVideos';


function AiVideo() {
  return (
	<>
	<Helmet>
		<link rel="canonical" href="https://oliverandthetimemachine.com/videos" />
		<title>Oliver and the Time Machine - OATTM Videos</title>
		<meta name="description" content="AI videos from the Oliver and the Time Machine series." />
	</Helmet>
	<HeaderTwo/>
	<BreadCrumb/>
	<main>
	<OATTMVideos/>
	</main>
	<Footer/>
	</>
  )
}

export default AiVideo