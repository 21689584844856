import React from 'react'
function OattmMainTwo() {

  
  return (
	 <section className="just-books-area just-books-bg pt-115 pb-120">
            <div className="container">
              <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-10">
                  <div className="section-title title-style-three white-title mb-70">
                    <h2>SUBSCRIBE</h2>
                    <p>Join our mail list and get the latest news and access to special offers</p>
                  </div>
                  <div className="just-gamers-list">
                    <ul>
                      <li>
                        <div className="just-gamers-list-icon">
                          <img src="assets/img/icon/new-book-icon.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Book release</h5>
                          <p>Be the first to know when the next book is being released</p>
                        </div>
                      </li>
                      <li>
                        <div className="just-gamers-list-icon">
                          <img src="assets/img/icon/gamer_list_icon02.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Art &amp; Videos</h5>
                          <p>Get access to Free screensavers and videos</p>
                        </div>
                      </li>
                      <li>
                        <div className="just-gamers-list-icon">
                          <img src="assets/img/icon/oattm-offers-icon.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Exclusive offers</h5>
                          <p>Get offers, competitions and more straight to your mailbox</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6 d-none d-lg-block">
                  <div className="just-gamers-img">
                    <img src="assets/img/images/oattm_oliver_future.jpg" alt="" className="just-gamers-character" />
                    <div className="just-gamers-circle-shape">
                      <img src="assets/img/images/gamers_circle_line.png" alt="" />
                      <img src="assets/img/images/gamers_circle_shape.png" alt="" className="rotateme" />
                    </div>
                    <img src="assets/img/images/oattm-clock-footer.png" alt="" className="gamers-chart-shape" />
                  </div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default OattmMainTwo