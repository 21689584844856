import React from 'react';
import { Helmet } from 'react-helmet';
import BreadCrumb from './BreadCrumb';
import HeaderTwo from '../../components/header/HeaderTwo';
import Footer from '../../components/footer/Footer';
import ShopArea from './ShopArea';


function Shop() {
  return (
	<>
	<Helmet>
		<link rel="canonical" href="https://oliverandthetimemachine.com/store" />
		<title>Oliver and the Time Machine - OATTM Store</title>
		<meta name="description" content="Buy the Oliver and the Time Machine merchandise." />
	</Helmet>
	 <HeaderTwo/>
     <main>
	 	<BreadCrumb/>
		<ShopArea/>
	 </main>
	 <Footer/>
	</>
  )
}

export default Shop