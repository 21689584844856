import React from 'react'
import {Link} from 'react-router-dom'



function OattmMain() {

  
  return (
	 <section className="featured-book-area position-relative pt-115 pb-90">
   

            <div className="featured-book-bg" />
            <div className="container">
              <header className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <div className="section-title title-style-three text-center mb-70">
                    <h2>Oliver and the Time Machine <span>Book Series</span></h2>
                    <p>Follow the extraordinary adventures of Oliver as he discovers time travel and faces incredible challenges</p>
                  </div>
                </div>
              </header>
              <div className="row featured-active">
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-book-item mb-30">
                    <div className="featured-book-thumb">
                      <img src="assets/img/newcover-book3.jpg" alt="" />
                    </div>
                    <div className="featured-book-content">
                      <h4><Link to="/#">Book <span>Three</span></Link></h4>
                      <div className="featured-book-meta">
                        <i className="fas fa-bell" />
                        <span>Coming Soon</span>
                      </div>
                    </div>
                    <div className="featured-book-content featured-book-overlay-content">
                      <div className="featured-book-icon"><img src="assets/img/icon/featured_book_oattm_icon.png" alt="" /></div>
                      <h4><Link to="/#">Book <span>Three</span></Link></h4>
                      <div className="featured-book-meta">
                        <i className="fas fa-bell" />
                        <span>Final book in series</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-book-item mb-30">
                    <div className="featured-book-thumb">
                      <img src="assets/img/newcover-book1.jpg" key='1' alt="" />
                    </div>
                    <div className="featured-book-content">
                      <h4><Link to="/#">Book <span>One</span></Link></h4>
                      <div className="featured-book-meta">
                        <i className="fas fa-bell" />
                        <span>#Paperback - #Kindle - #Apple - #ebook</span>
                      </div>
                    </div>
                    <div className="featured-book-content featured-book-overlay-content">
                      <div className="featured-book-icon"><img src="assets/img/icon/featured_book_oattm_icon.png" alt="" /></div>
                      <a href="https://www.amazon.com/dp/1738510514" target="_blank" rel="noopener noreferrer" className="btn transparent-btn"><i className="fas fa-shopping-basket" />BUY Now From £2.99</a>
                      <div className="featured-book-meta">
                        <i className="fas fa-bell" />
                        <span>Published</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-book-item mb-30">
                    <div className="featured-book-thumb">
                      <img src="assets/img/newcover-book2.jpg" key="1" alt="" />
                    </div>
                    <div className="featured-book-content">
                      <h4><Link to="/#">Book <span>Two</span></Link></h4>
                      <div className="featured-book-meta">
                        <i className="fas fa-bell" />
                        <span>Release May 2025</span>
                      </div>
                    </div>
                    <div className="featured-book-content featured-book-overlay-content">
                      <div className="featured-book-icon"><img src="assets/img/icon/featured_book_oattm_icon.png" alt="" /></div>
                      <h4><Link to="/#">Book <span>Two</span></Link></h4>
                      <div className="featured-book-meta">
                        <i className="fas fa-bell" />
                        <span>Second book in series</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
  )
}

export default OattmMain