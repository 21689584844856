import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeTwo from './pages/homes/HomeTwo';
import Shop from './pages/store/Shop';
import Artwork from './pages/artwork/Overview';
import BookOne from './pages/singlebook/BookOne';
import BookTwo from './pages/singlebook/BookTwo';
import BookThree from './pages/singlebook/BookThree';
import PrivacyPolicy from './pages/privacy/PrivacyPolicy'
import AiVideo from './pages/videos/AiVideo';
import Skins from './pages/roblox/Skins';
import Music from './pages/music/Music';
//import Loader from "react-js-loader";

// Other imports remain unchanged

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomeTwo />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/artwork' element={<Artwork />} />
        <Route path='/book-one-the-discovery' element={<BookOne />} />
        <Route path='/book-two-the-awakening' element={<BookTwo />} />
        <Route path='/book-three-the-tbc' element={<BookThree />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/roblox' element={<Skins />} />
        <Route path='/music' element={<Music />} />

        <Route path='/videos' element={<AiVideo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
