import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo';
import Footer from '../../components/footer/Footer';
import Breadcrumb from './Breadcrumb';
import BookTwoArea from './BookTwoArea';
import BookTwoRelease from './BookTwoRelease'


function BookTwo() {
  return (
	<>
	<HeaderTwo/>
	<main>
	<Breadcrumb/>
	<BookTwoArea/>
	<BookTwoRelease/>
	</main>
	<Footer/>
	</>
  )
}

export default BookTwo