import React from 'react';


function Footer() {
  return (
	   <footer>
        <div className="footer-top footer-bg">
        <div className="newsletter-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="newsletter-wrap">
              <div className="section-title newsletter-title">
                <h2>Get the latest News &<span> Offers</span></h2>
              </div>
              <div className="newsletter-form">
                <form
                  action="https://oliverandthetimemachine.us21.list-manage.com/subscribe/post?u=225bce0a721672f3d845931cd&amp;id=af18c8fe85&amp;f_id=00d5e2e6f0"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                >
                  <div className="newsletter-form-grp">
                    <i className="far fa-envelope" />
                    <input
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      required=""
                      placeholder="Enter your email..."
                      autoComplete="email"
                      aria-label="Subscribe to newsletter"
                    />
                  </div>
                  <button type="submit" aria-label="Subscribe">SUBSCRIBE <i className="fas fa-paper-plane" /></button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="footer-logo mb-35">
                    <a href="/"><img src="assets/img/logo/logo.png" alt="OATTM Logo" /></a>
                  </div>
                  <div className="footer-text">
                    <p>OATTM - Oliver and the Time Machine</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>General</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a  href="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Follow us</h5>
                  </div>
                  <div className="footer-social">
                    <ul>
                      <li><a  href="https://www.facebook.com/profile.php?id=61553549675632" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a></li>
                      <li><a  href="https://www.instagram.com/oattm1" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a></li>
                      <li><a  href="https://www.tiktok.com/@oattmbooks" target="_blank" rel="noopener noreferrer"><i className="fab fa-pinterest-p" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-fire"><img src="assets/img/images/oattm-red-car-footer-left.png" alt="" /></div>
          <div className="footer-fire footer-fire-right"><img src="assets/img/images/oattm-red-car-footer-right.png" alt="" /></div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="copyright-text">
                  <p>Copyright © 2023 <a  href="/#">Oliver and the Time Machine</a> All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer;
