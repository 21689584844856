import React from 'react'

const OATTMMusic = () => {
  return (
    <section className="trending-video-area">
        <div className="container">
          <div className="row align-items-center mb-30">
            <div className="col-sm-6">
              <div className="hf-section-title">
                <h4 className="title">OATTM Music</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="trending-video-bookplay-item mb-50">
                <iframe 
                  style={{borderRadius: "12px"}} 
                  src="https://open.spotify.com/embed/album/0LLry3OaRWyxe7bQ8FCqa7?utm_source=generator" 
                  width="100%" 
                  height="352" 
                  frameBorder="0" 
                  allowFullScreen="" 
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                  loading="lazy"
                  title="OATTM Spotify Album"
                />
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}

export default OATTMMusic