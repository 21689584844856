import React from 'react';
import { Helmet } from 'react-helmet';
import HeaderTwo from '../../components/header/HeaderTwo';
import Footer from '../../components/footer/Footer';
import Breadcrumb from './Breadcrumb';
import BookOneArea from './BookOneArea';
import BookOneRelease from './BookOneRelease';

function BookOne() {
  return (
	<>
	<Helmet>
		<link rel="canonical" href="https://oliverandthetimemachine.com/book-one" />
		<title>Oliver and the Time Machine - Book One: The Beginning</title>
		<meta name="description" content="Discover the first book in the Oliver and the Time Machine series. Join Oliver as he discovers a mysterious time-traveling car and an AI companion named Rosie." />
	</Helmet>
	<HeaderTwo/>
	<main>
	<Breadcrumb/>
	<BookOneArea/>
	<BookOneRelease/>
	</main>
	<Footer/>
	</>
  )
}

export default BookOne